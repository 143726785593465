import {
  mobile,
  backend,
  creator,
  web,
  javascript,
  typescript,
  html,
  express,
  nextjs,
  expo,
  graphql,
  css,
  reactjs,
  tailwind,
  nodejs,
  mongodb,
  git,
  nu,
  upwork,
  adrightly,
  daira,
  weatherApp,
  goldRateApp,
} from "../assets";

export const navLinks = [
  {
    id: "#about",
    title: "About",
  },
  {
    id: "#experience",
    title: "Experience",
  },
  {
    id: "#skills",
    title: "Skills",
  },
  {
    id: "#work",
    title: "Projects",
  },
  {
    id: "#contact",
    title: "Contact",
  },
  {
    id: "resume.pdf",
    title: "Resume",
  },
];

const services = [
  {
    title: "Full Stack Web Developer",
    icon: web,
  },
  {
    title: "React Native Developer",
    icon: mobile,
  },
  {
    title: "Backend Developer",
    icon: backend,
  },
  {
    title: "Expo Developer",
    icon: creator,
  },
];

const technologies = [
  {
    name: "Next JS",
    icon: nextjs,
  },
  {
    name: "Expo",
    icon: expo,
  },
  {
    name: "GraphQL",
    icon: graphql,
  },
  {
    name: "Tailwind CSS",
    icon: tailwind,
  },
  {
    name: "React JS",
    icon: reactjs,
  },
  {
    name: "MongoDB",
    icon: mongodb,
  },
  {
    name: "Express JS",
    icon: express,
  },
  {
    name: "Node JS",
    icon: nodejs,
  },
  {
    name: "Git",
    icon: git,
  },
  {
    name: "TypeScript",
    icon: typescript,
  },
  {
    name: "JavaScript",
    icon: javascript,
  },
  {
    name: "CSS 3",
    icon: css,
  },
  {
    name: "HTML 5",
    icon: html,
  },
];

const experiences = [
  {
    title: "Daira' 24 Web Developer",
    company_name: "FAST NUCES · Part-time",
    icon: nu,
    iconBg: "#383E56",
    date: "Apr 2024 - Apr 2024 · 1 month",
    points: [],
  },
  {
    title: "Full Stack Developer",
    company_name: "Upwork · Freelance",
    icon: upwork,
    iconBg: "#E6DEDD",
    date: "Jun 2024 - Present · 4 months",
    points: [],
  },
  {
    title: "Daira' 25 Web Developer",
    company_name: "FAST NUCES · Part-time",
    icon: nu,
    iconBg: "#383E56",
    date: "Sep 2024 - Present · 1 month",
    points: [],
  },
  {
    title: "MERN Stack Developer",
    company_name: "AdRightly · Internship",
    icon: adrightly,
    iconBg: "#E6DEDD",
    date: "Sep 2024 - Present · 1 month",
    points: [],
  },
];

const testimonials = [
  {
    testimonial:
      "I thought it was impossible to make a website as beautiful as our product, but Rick proved me wrong.",
    name: "Sara Lee",
    designation: "CFO",
    company: "Acme Co",
    image: "https://randomuser.me/api/portraits/women/4.jpg",
  },
  {
    testimonial:
      "I've never met a web developer who truly cares about their clients' success like Rick does.",
    name: "Chris Brown",
    designation: "COO",
    company: "DEF Corp",
    image: "https://randomuser.me/api/portraits/men/5.jpg",
  },
  {
    testimonial:
      "After Rick optimized our website, our traffic increased by 50%. We can't thank them enough!",
    name: "Lisa Wang",
    designation: "CTO",
    company: "456 Enterprises",
    image: "https://randomuser.me/api/portraits/women/6.jpg",
  },
];

const projects = [
  {
    name: "Daira'24 Website",
    description:
      "Web-based platform that allows users to register for Daira event held at FAST NUCES CFD Campus.",
    tags: [
      {
        name: "react",
        color: "blue-text-gradient",
      },
      {
        name: "mongodb",
        color: "green-text-gradient",
      },
      {
        name: "materialui",
        color: "pink-text-gradient",
      },
    ],
    image: daira,
    source_code_link: "https://daira.pk",
  },
  {
    name: "Weather App",
    description:
      "A mobile app that allows users to check the weather of any city in the world and also save user's last location to show weather.",
    tags: [
      {
        name: "react-native",
        color: "blue-text-gradient",
      },
      {
        name: "expo",
        color: "green-text-gradient",
      },
      {
        name: "weather-api",
        color: "pink-text-gradient",
      },
    ],
    image: weatherApp,
    source_code_link:
      "https://github.com/mraza176/Weather-App-in-React-Native-Expo",
  },
  {
    name: "Gold Rate App",
    description:
      "A mobile app that provides real-time gold price updates and a price calculator, all in a user-friendly interface.",
    tags: [
      {
        name: "react-native",
        color: "blue-text-gradient",
      },
      {
        name: "expo",
        color: "green-text-gradient",
      },
      {
        name: "context-api",
        color: "pink-text-gradient",
      },
    ],
    image: goldRateApp,
    source_code_link:
      "https://github.com/mraza176/Gold-Rate-App-in-React-Native-Expo",
  },
];

export { services, technologies, experiences, testimonials, projects };
